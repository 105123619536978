import React, { useState } from "react";
import styled from "styled-components";
import { navigate } from "@reach/router";

import { theme, envVars } from "../config";

import VStack from "./VStack";
import Spacer from "./Spacer";
import HStack from "./HStack";
import Text from "./Text";
import { Chevron } from "../assets";

const Container = styled.div`
  display: none;
  @media (${theme.breakpoints.mediumAndUp}) {
    display: flex;
    min-width: 200px;
    background-color: ${theme.colors.white100};
    border-right: 0.5px solid ${theme.colors.grey30};
    &:hover {
      color: ${theme.colors.white100};
    }
  }
`;

const MobileContainer = styled.div`
  display: flex;
  position: absolute;
  height: 100vh;
  width: 75%;
  background-color: ${theme.colors.white100};
  z-index: 1000;
  border-right: 0.5px solid ${theme.colors.grey30};
  transition: transform 300ms ease-in-out;
  @media (${theme.breakpoints.mediumAndUp}) {
    transition: transform 300ms ease-in-out;
    display: none;
  }
`;

const SidebarItemContainer = styled.div`
  width: 100%;
  color: ${({ active }) =>
    active ? theme.colors.white100 : theme.colors.primary100};
  background: ${({ active }) =>
    active ? theme.colors.primary100 : theme.colors.white100};
  y100 &:hover {
    background: ${theme.colors.primary100};
    color: ${theme.colors.white100};
  }
  cursor: pointer;
`;

const ActiveVStack = styled(VStack)`
  width: 100%;
  color: ${({ active }) =>
    active ? theme.colors.white100 : theme.colors.primary100};
  background: ${({ active }) =>
    active ? theme.colors.primary100 : theme.colors.white100};
  y100 &:hover {
    background: ${theme.colors.primary100};
    color: ${theme.colors.white100};
  }
  cursor: pointer;
`;

const ItemText = styled.h1`
  font-size: ${theme.spacing.x5};
  font-weight: 600;
  padding-left: ${theme.spacing.x2};
`;

const SubItemText = styled(Text)`
  padding-left: ${theme.spacing.x4};
`;

const SidebarItem = ({ item, active, setActiveRoute, closeSidebar }) => {
  const [activeSubRoute, setActiveSubRoute] = useState(0);
  const [accordionOpen, setAccordionOpen] = useState(false);
  return !item.isCollapsable ? (
    <SidebarItemContainer
      active={active}
      onClick={() => {
        closeSidebar();
        setActiveRoute();
      }}
    >
      <HStack align="center" justify="space-between">
        <ItemText>{item.title}</ItemText>
      </HStack>
    </SidebarItemContainer>
  ) : (
    <SidebarItemContainer>
      <VStack>
        <HStack
          align="center"
          justify="space-around"
          onClick={() => {
            setAccordionOpen(!accordionOpen);
            localStorage.setItem("accordionOpen", true);
          }}
        >
          <ItemText>{item.title}</ItemText>
          <Chevron
            direction={accordionOpen ? "top" : "bottom"}
            fill={theme.colors.primary100}
          />
        </HStack>
        {accordionOpen &&
          item.subItems.map((subItem, idx) => {
            return (
              <ActiveVStack
                align="flex-start"
                active={active && activeSubRoute === idx}
                onClick={async () => {
                  closeSidebar();
                  await localStorage.setItem("activeSubRoute", idx);
                  await setActiveSubRoute(idx);
                  setActiveRoute(subItem.route);
                }}
              >
                <Spacer height="x2" />
                <SubItemText
                  color={
                    active && activeSubRoute === idx ? "white100" : "primary100"
                  }
                  size="x3"
                  weight="semibold"
                >
                  {subItem.title}
                </SubItemText>
                <Spacer height="x2" />
              </ActiveVStack>
            );
          })}
      </VStack>
    </SidebarItemContainer>
  );
};

const Sidebar = ({
  showSidebar,
  activeRoute,
  setActiveRoute,
  setShowSidebar,
}) => {
  const menuItems = [
    { title: "Dashboard", route: "/dashboard" },
    // {title: 'Elo Ratings', route: '/elo'},
    { title: "All-time Stats", route: "/alltime" },
    { title: "Records", route: "/records" },
    {
      title: "The Howden Way Young Horse Leagues",
      isCollapsable: true,
      subItems: [
        { title: "5 Year Old League", route: "/5yo_power_average" },
        { title: "6 Year Old League", route: "/6yo_power_average" },
        { title: "7 Year Old League", route: "/7yo_power_average" },
        { title: "8 & 9 Year Old League", route: "/8_9yo_power_average", },
      ],
    },
    {
      title: "Youth Region Leagues",
      isCollapsable: true,
      subItems: [
        { title: "Scotland", route: "/youth_leagues/scotland/" },
        { title: "North", route: "/youth_leagues/north/" },
        { title: "Wales & West Midlands", route: "/youth_leagues/wales_and_west_midlands/" },
        { title: "East Midlands", route: "/youth_leagues/east_midlands/" },
        { title: "Central", route: "/youth_leagues/central/" },
        { title: "East", route: "/youth_leagues/east/" },
        { title: "South West", route: "/youth_leagues/south_west/" },
        { title: "South East", route: "/youth_leagues/south_east/" },
      ],
    },
    {
      title: "OBP Leagues",
      isCollapsable: true,
      subItems: [
        { title: "BE80 OBP League", route: "/be80/" + envVars.currentYear },
        { title: "BE90 OBP League", route: "/be90/" + envVars.currentYear },
        { title: "BE100 OBP League", route: "/be100/" + envVars.currentYear },
        { title: "Newcomer OBP League", route: "/be_newcomer/" + envVars.currentYear },
        { title: "Overall OBP League", route: "/be_overall/" + envVars.currentYear },
      ],
    },
    {
      title: "Scottish Grass Roots League",
      isCollapsable: true,
      subItems: [
        { title: "BE90 League", route: "/scottish_grass_roots_be90" },
        { title: "BE100 League", route: "/scottish_grass_roots_be100" },
      ],
    },
    { title: "About the Leagues", route: "/leagues_about" },
  ];

  if (showSidebar) {
    return window.location.href.includes("howden") ? null : (
      <MobileContainer>
        <VStack>
          {menuItems.map((item, idx) => {
            return (
              <SidebarItem
                key={idx}
                item={item}
                closeSidebar={() => setShowSidebar(false)}
                setActiveRoute={async (subRoute) => {
                  if (item.isCollapsable) {
                    await localStorage.setItem("accordionOpen", idx);
                    await localStorage.setItem("activeRoute", idx);
                    await setActiveRoute(idx);
                    navigate(subRoute);
                  } else {
                    await localStorage.setItem("activeRoute", idx);
                    await setActiveRoute(idx);
                    navigate(item.route);
                  }
                }}
                active={activeRoute === idx}
              />
            );
          })}
        </VStack>
      </MobileContainer>
    );
  } else {
    return window.location.href.includes("howden") ? null : (
      <Container>
        <VStack>
          {menuItems.map((item, idx) => {
            return (
              <SidebarItem
                key={idx}
                item={item}
                closeSidebar={() => { }}
                setActiveRoute={async (subRoute) => {
                  if (item.isCollapsable) {
                    await localStorage.setItem("accordionOpen", idx);
                    await localStorage.setItem("activeRoute", idx);
                    await setActiveRoute(idx);
                    navigate(subRoute);
                  } else {
                    await localStorage.setItem("activeRoute", idx);
                    await setActiveRoute(idx);
                    navigate(item.route);
                  }
                }}
                active={activeRoute === idx}
              />
            );
          })}
        </VStack>
      </Container>
    );
  }
};

export default Sidebar;
