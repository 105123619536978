import React from "react";
import { Router } from "@reach/router";
import {
  SingleMetric,
  Records,
  AllTime,
  Dashboard,
  OBPLeagues,
  AboutLeagues,
  PowerAverageLeague,
  HowdenWayPrelim,
  RegionalPwrAvgSummary,
  RegionalPwrAvgFull,
} from "./screens";
import { envVars } from "./config";

const Navigation = () => {
  return (
    <>
      <Router>
        <Dashboard path="/dashboard" default />
        <AllTime path="/alltime" />
        <Records path="/records" />
        <SingleMetric path="/analysis" />
        <RegionalPwrAvgSummary path="/youth_leagues/:region" />
        <RegionalPwrAvgFull path="/youth_leagues/:region/:classLevel" />
        <OBPLeagues path="/be80/:year" partner="BE80" reportName="BE80" />
        <OBPLeagues path="/be90/:year" partner="BE90" reportName="BE90" />
        <OBPLeagues path="/be100/:year" partner="BE100" reportName="BE100" />
        <OBPLeagues path="/be_newcomer/:year" partner="Newcomer/Returner" reportName="Newcomers" />
        <OBPLeagues path="/be_overall/:year" partner="Overall" reportName="Overall" />
        <PowerAverageLeague
          path="/scottish_grass_roots_be90"
          title="Scottish Grass Roots League BE90"
          showRegion="scotland"
          groupBy="combination"
          heightLevels={[2]}
          beGrassRoots={true}
          minCountingRuns={3}
          dateFrom="2023-07-20"
          dateTo="2024-07-30"
          excludePayg={false}
          text={[
            {
              title: "Power Average: ",
              text: "The average of a combination’s three best finishing scores at Scottish competitions.",
            },
            {
              title: "Details: ",
              text: `For the 2024 Championships riders qualify via the Scottish Grassroots leagues.`,
            },
            {
              title: "",
              text: `The league is based on the average of a Combination's best 3 runs during the qualifying period ` +
                `and the top 45 Combinations in the league qualify for the Championships at Blair. BE reserves the right ` +
                `to adjust the qualification criteria and adjust the number that qualify from the leagues. For the 2024 ` +
                `Championships riders qualify via the Scottish Grassroots leagues.`,
            },
            {
              title: "",
              text: `All BE90 classes including BE90Ou18 classes, 2023 National qualifier and Regional Championship classes, ` +
                `are qualifying classes for the BE90 Scottish Grassroots Championships.`,
            },
            {
              title: "",
              text: `The qualifying period for the league is between 20 July 2023 and the Ballot date of the Championships in 2024.`,
            },
            {
              title: "",
              text: `Horses must be Grade 4 at the date of the BE90 Scottish Grassroots Championships and for the BE100 Scottish ` +
                `Grassroots Championship horses are to be Grade 4 or may have up to 7 Grading points (Grade 3) between the ballot date ` +
                `of the event they qualify at and the date of the Championships itself. `,
            },
            {
              title: "",
              text: `Each Horse & Rider must be eligible to ride in the relevant Class at the time of the Competition but eligibility ` +
                `restrictions also includes that riders must not have completed at 5* level and must not have competed at Intermediate or ` +
                `higher for the current or preceding 5 seasons.`,
            },
            {
              title: "",
              text: `Substitutions are not allowed and horse and rider must be eligible to compete in the classes at the time of the ` +
                `competition. Full Rider Eligibility rules are in Annex 6 of the BE rulebook.`,
            },
            {
              title: "",
              text: `The top 20% of eligible combinations at the BE90 & BE100 Scottish Championships will also gain direct qualification ` +
                `to Badminton for the 2025 Championships. Please note that there are differences between the eligibility criteria for the ` +
                `Scottish Grassroots Championships and Grassroots Championships at Badminton therefore not all of the top 20% from Blair will ` +
                `necessarily be eligible to proceed to the National Championships at Badminton. `,
            },
            {
              title: "",
              text: `* Grassroots riders are defined as having not competed at Intermediate or higher in ` +
                `the current or preceding 5 seasons, or at CCI5*.`,
              footnote: true,
            },
          ]}
        />
        <PowerAverageLeague
          path="/scottish_grass_roots_be100"
          title="Scottish Grass Roots League BE100"
          showRegion="scotland"
          groupBy="combination"
          heightLevels={[3]}
          beGrassRoots={true}
          minCountingRuns={3}
          dateFrom="2023-07-20"
          dateTo="2024-07-30"
          excludePayg={false}
          text={[
            {
              title: "Power Average: ",
              text: "The average of a combination’s three best finishing scores at Scottish competitions.",
            },
            {
              title: "Details: ",
              text: `For the 2024 Championships riders qualify via the Scottish Grassroots leagues.`,
            },
            {
              title: "",
              text: `The league is based on the average of a Combination's best 3 runs during the qualifying period ` +
                `and the top 45 Combinations in the league qualify for the Championships at Blair. BE reserves the right ` +
                `to adjust the qualification criteria and adjust the number that qualify from the leagues. For the 2024 ` +
                `Championships riders qualify via the Scottish Grassroots leagues.`,
            },
            {
              title: "",
              text: `All BE100 classes including BE100Ou18 classes, the 2023 National qualifier and 2024 Regional Championship ` +
                `classes, are qualifying classes for the BE100 Scottish Grassroots Championships.`,
            },
            {
              title: "",
              text: `The qualifying period for the league is between 20 July 2023 and the Ballot date of the Championships in 2024.`,
            },
            {
              title: "",
              text: `Horses must be Grade 4 at the date of the BE90 Scottish Grassroots Championships and for the BE100 Scottish ` +
                `Grassroots Championship horses are to be Grade 4 or may have up to 7 Grading points (Grade 3) between the ballot date ` +
                `of the event they qualify at and the date of the Championships itself. `,
            },
            {
              title: "",
              text: `Each Horse & Rider must be eligible to ride in the relevant Class at the time of the Competition but eligibility ` +
                `restrictions also includes that riders must not have completed at 5* level and must not have competed at Intermediate or ` +
                `higher for the current or preceding 5 seasons.`,
            },
            {
              title: "",
              text: `Substitutions are not allowed and horse and rider must be eligible to compete in the classes at the time of the ` +
                `competition. Full Rider Eligibility rules are in Annex 6 of the BE rulebook.`,
            },
            {
              title: "",
              text: `The top 20% of eligible combinations at the BE90 & BE100 Scottish Championships will also gain direct qualification ` +
                `to Badminton for the 2025 Championships. Please note that there are differences between the eligibility criteria for the ` +
                `Scottish Grassroots Championships and Grassroots Championships at Badminton therefore not all of the top 20% from Blair will ` +
                `necessarily be eligible to proceed to the National Championships at Badminton. `,
            },
            {
              title: "",
              text: `* Grassroots riders are defined as having not competed at Intermediate or higher in ` +
                `the current or preceding 5 seasons, or at CCI5*.`,
              footnote: true,
            },
          ]}
        />
        <PowerAverageLeague
          path="/5yo_power_average"
          title="The Howden Way Power Average League for 5 Year Olds"
          text={[
            {
              title: "Power Average: ",
              text: "The average of a horse’s three best finishing scores, the Power Average highlights the ability to repeatedly finish on a low score.",
            },
            {
              title: "Details: ",
              text: `For this league, we calculate the Power Average using the three best finishing scores from all ${envVars.currentYear} British Eventing competitions at the BE100, BE105, and CC1* levels. Only 5 year-old horses with three or more ${envVars.currentYear} BE completions at those levels are eligible to win. In the event of a tie for the league win, the fourth-best finishing score of each horse will be the tie-breaker. If one of the horse’s only has three runs, the horse with more runs will be given the league title. This rule applies if the tie-break must go to a fifth score –  a horse with only four runs will earn second place to the horse with five or more runs; and so on as necessary.`,
            },
            {
              title: "",
              text: "*To appear on the league, the last rider to register results with the horse must hold a current British Eventing ‘Standard’ or ‘Premier’ membership.",
              footnote: true,
            },
          ]}
          erLevels={[2, 3]}
          horseAge={[5]}
          bannerImageDetails={{ imageSrc: require("./assets/beBanner.png"), clickUrl: "https://www.britisheventing.com/join/join-2022" }}
          advertsDetils={[
            { imageSrc: require("./assets/be4.png"), clickUrl: "https://qr.equiratings.com/heretohelp" },
            { imageSrc: require("./assets/be5.png"), clickUrl: "https://qr.equiratings.com/bepodcast" },
            { imageSrc: require("./assets/be3.png"), clickUrl: "https://www.eventingcheck.co.uk/index.php" },
            { imageSrc: require("./assets/be1.png"), clickUrl: "https://news.britisheventing.com/category/be-event-updates/" },
          ]}
        />
        <PowerAverageLeague
          path="/6yo_power_average"
          title="The Howden Way Power Average League for 6 Year Olds"
          erLevels={[4, 5, 6]}
          horseAge={[6]}
          text={[
            {
              title: "Power Average: ",
              text: "The average of a horse’s three best finishing scores, the Power Average highlights the ability to repeatedly finish on a low score.",
            },
            {
              title: "Details: ",
              text: `For this league, we calculate the Power Average using the three best finishing scores from all ${envVars.currentYear} British Eventing competitions at the Novice, CCI2*-S and CCI2*-L* levels. Only 6 year-old horses with three or more ${envVars.currentYear} BE completions at those levels are eligible to win. In the event of a tie for the league win, the fourth-best finishing score of each horse will be the tie-breaker. If one of the horse’s only has three runs, the horse with more runs will be given the league title. This rule applies if the tie-break must go to a fifth score – a horse with only four runs will earn second place to the horse with five or more runs; and so on as necessary.  `,
            },
            {
              title: "",
              text: "*To appear on the league, the last rider to register results with the horse must hold a current British Eventing ‘Standard’ or ‘Premier’ membership.",
              footnote: true,
            },
          ]}
          bannerImageDetails={{ imageSrc: require("./assets/beBanner.png"), clickUrl: "https://www.britisheventing.com/join/join-2022" }}
          advertsDetils={[
            { imageSrc: require("./assets/be4.png"), clickUrl: "https://qr.equiratings.com/heretohelp" },
            { imageSrc: require("./assets/be5.png"), clickUrl: "https://qr.equiratings.com/bepodcast" },
            { imageSrc: require("./assets/be3.png"), clickUrl: "https://www.eventingcheck.co.uk/index.php" },
            { imageSrc: require("./assets/be1.png"), clickUrl: "https://news.britisheventing.com/category/be-event-updates/" },
          ]}
        />
        <PowerAverageLeague
          path="/7yo_power_average"
          title="The Howden Way Power Average League for 7 Year Olds"
          erLevels={[7, 8, 9]}
          text={[
            {
              title: "Power Average: ",
              text: "The average of a horse’s three best finishing scores, the Power Average highlights the ability to repeatedly finish on a low score.",
            },
            {
              title: "Details: ",
              text: `For this league, we calculate the Power Average using the three best finishing scores from all ${envVars.currentYear} British Eventing competitions at the Intermediate, CCI3*-S and CCI3*-L levels. Only 7 year-old horses with three or more ${envVars.currentYear} BE completions at those levels are eligible to win. In the event of a tie for the league win, the fourth-best finishing score of each horse will be the tie-breaker. If one of the horse’s only has three runs, the horse with more runs will be given the league title. This rule applies if the tie-break must go to a fifth score – a horse with only four runs will earn second place to the horse with five or more runs; and so on as necessary.  :`,
            },
            {
              title: "",
              text: "*To appear on the league, the last rider to register results with the horse must hold a current British Eventing ‘Standard’ or ‘Premier’ membership.",
              footnote: true,
            },
          ]}
          horseAge={[7]}
          bannerImageDetails={{ imageSrc: require("./assets/beBanner.png"), clickUrl: "https://www.britisheventing.com/join/join-2022" }}
          advertsDetils={[
            { imageSrc: require("./assets/be4.png"), clickUrl: "https://qr.equiratings.com/heretohelp" },
            { imageSrc: require("./assets/be5.png"), clickUrl: "https://qr.equiratings.com/bepodcast" },
            { imageSrc: require("./assets/be3.png"), clickUrl: "https://www.eventingcheck.co.uk/index.php" },
            { imageSrc: require("./assets/be1.png"), clickUrl: "https://news.britisheventing.com/category/be-event-updates/" },
          ]}
        />
        <PowerAverageLeague
          path="/8_9yo_power_average"
          title="The Howden Way Power Average League for 8 and 9 Year Olds"
          text={[
            {
              title: "Power Average: ",
              text: "The average of a horse’s three best finishing scores, the Power Average highlights the ability to repeatedly finish on a low score.",
            },
            {
              title: "Details: ",
              text: `For this league, we calculate the Power Average using the three best finishing scores from all ${envVars.currentYear} British Eventing competitions at the Advanced, CCI4*-S and CCI4*-L levels. Only 8 or 9 year-old horses with three or more ${envVars.currentYear} BE completions at those levels are eligible to win. In the event of a tie for the league win, the fourth-best finishing score of each horse will be the tie-breaker. If one of the horse’s only has three runs, the horse with more runs will be given the league title. This rule applies if the tie-break must go to a fifth score – a horse with only four runs will earn second place to the horse with five or more runs; and so on as necessary.  `,
            },
            {
              title: "",
              text: "*To appear on the league, the last rider to register results with the horse must hold a current British Eventing ‘Standard’ or ‘Premier’ membership.",
              footnote: true,
            },
          ]}
          erLevels={[10, 11, 12]}
          horseAge={[8, 9]}
          bannerImageDetails={{ imageSrc: require("./assets/beBanner.png"), clickUrl: "https://www.britisheventing.com/join/join-2022" }}
          advertsDetils={[
            { imageSrc: require("./assets/be4.png"), clickUrl: "https://qr.equiratings.com/heretohelp" },
            { imageSrc: require("./assets/be5.png"), clickUrl: "https://qr.equiratings.com/bepodcast" },
            { imageSrc: require("./assets/be3.png"), clickUrl: "https://www.eventingcheck.co.uk/index.php" },
            { imageSrc: require("./assets/be1.png"), clickUrl: "https://news.britisheventing.com/category/be-event-updates/" },
          ]}
        />
        <AboutLeagues path="/leagues_about" />
        <HowdenWayPrelim path="/howden_way" />
      </Router>
    </>
  );
};

export default Navigation;
